import React, { useState, useEffect } from "react";
// Import Figure and ProgressBar from React Bootstrap
import Figure from "react-bootstrap/Figure";
import ProgressBar from "react-bootstrap/ProgressBar";
// Import your CSS
import "../assets/css/AppLoadingIndicator.css";

const AppLoadingIndicator = ({
  progress = 0,
  status = "Loading application...",
  isVisible = true,
  onComplete = () => {},
  minDisplayTime = 1000,
}) => {
  const [opacity, setOpacity] = useState(1);
  const [displayProgress, setDisplayProgress] = useState(0);
  const [startTime] = useState(Date.now());

  // Animate progress smoothly
  useEffect(() => {
    if (progress > displayProgress) {
      const animate = () => {
        setDisplayProgress((prev) => {
          const next = Math.min(prev + 2, progress);
          if (next < progress) {
            requestAnimationFrame(animate);
          }
          return next;
        });
      };
      requestAnimationFrame(animate);
    }
  }, [progress, displayProgress]);

  // Handle fade out animation
  useEffect(() => {
    if (progress >= 100) {
      const elapsedTime = Date.now() - startTime;
      const remainingTime = Math.max(0, minDisplayTime - elapsedTime);
      const timer = setTimeout(() => setOpacity(0), remainingTime + 300);
      const completeTimer = setTimeout(() => onComplete(), remainingTime + 800);
      return () => {
        clearTimeout(timer);
        clearTimeout(completeTimer);
      };
    }
  }, [progress, onComplete, startTime, minDisplayTime]);

  if (!isVisible) return null;

  return (
    <div
      className="app-loading-container text-center"
      style={{ opacity, transition: "opacity 0.5s ease-in-out" }}
    >
      <div className="app-loading-content text-center">
        <Figure className="mb-3 text-center">
          <Figure.Image
            src="/Q_logo_256_prog.jpeg"
            alt="Application Logo"
            className="app-loading-logo"
          />
          <Figure.Caption className="app-loading-status">
            {status}
          </Figure.Caption>
        </Figure>

        {/* Progress Bar */}
        <div className="app-loading-progress-wrapper">
          <ProgressBar
            now={displayProgress}
            animated
            className="app-loading-progress-bar-rb"
          />
        </div>
      </div>
    </div>
  );
};

export default AppLoadingIndicator;
