import React, { createContext, useContext, useState, useEffect } from "react";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import { createPortal } from "react-dom";

const VersionContext = createContext(null);

export const VersionProvider = ({ children }) => {
  const [currentVersion, setCurrentVersion] = useState(() => {
    return (
      (typeof window !== "undefined" && (window.__APP_VERSION__ || null)) ||
      "1.0.0"
    );
  });
  const [showUpdateAlert, setShowUpdateAlert] = useState(false);
  const [checking, setChecking] = useState(false);

  const checkVersion = async () => {
    if (checking) return;

    try {
      setChecking(true);

      const response = await fetch(`/version.json?t=${new Date().getTime()}`, {
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
        },
      });

      if (!response.ok) throw new Error("Failed to fetch version");

      const data = await response.json();
      console.log("data.version=", data.version);

      if (data.version && data.version !== currentVersion) {
        setShowUpdateAlert(true);
      } else {
        console.log("Versions match or no update needed");
      }
    } catch (error) {
      console.error("Error checking version:", error);
    } finally {
      setChecking(false);
    }
  };

  const handleUpdate = () => {
    console.log("Handling update...");
    if ("caches" in window) {
      caches.keys().then((names) => {
        console.log("Clearing caches:", names);
        names.forEach((name) => {
          caches.delete(name);
        });
      });
    }
    window.location.reload(true);
  };

  useEffect(() => {
    const initialCheck = setTimeout(() => {
      console.log("Running initial version check...");
      checkVersion();
    }, 2000);

    const interval = setInterval(() => {
      checkVersion();
    }, 1440 * 60 * 1000);

    return () => {
      clearTimeout(initialCheck);
      clearInterval(interval);
    };
  }, []);

  const UpdateAlert = () => {
    if (!showUpdateAlert) return null;

    const alertContent = (
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          zIndex: 9999,
          padding: "1rem",
        }}
      >
        <div style={{ maxWidth: "1200px", margin: "0 auto" }}>
          <Alert variant="info" className="mb-0 shadow-lg">
            <div className="d-flex align-items-center justify-content-between">
              <div>
                <Alert.Heading className="h5 mb-1">
                  New Version Available
                </Alert.Heading>
                <p className="mb-0">
                  A new version of the application is available.
                </p>
              </div>
              <Button
                variant="outline-info"
                onClick={handleUpdate}
                className="ms-3"
              >
                Update Now
              </Button>
            </div>
          </Alert>
        </div>
      </div>
    );

    return createPortal(alertContent, document.body);
  };

  return (
    <VersionContext.Provider value={{ currentVersion, checkVersion }}>
      {children}
      <UpdateAlert />
    </VersionContext.Provider>
  );
};

export const useVersion = () => {
  const context = useContext(VersionContext);
  if (!context) {
    throw new Error("useVersion must be used within a VersionProvider");
  }
  return context;
};
