import React, { useEffect, Suspense, lazy, useState } from "react";
import {
  RouterProvider,
  createBrowserRouter,
  Navigate,
} from "react-router-dom";
import { AuthProvider, useAuth } from "./contexts/AuthContext";
import { ThemeProvider } from "./components/ThemeProvider";
import { SettingsProvider } from "./contexts/SettingsContext";
import ErrorBoundary from "./components/ErrorBoundary";
import ProtectedRoute from "./components/ProtectedRoute";
import { VersionProvider } from "./contexts/VersionProvider.jsx";
import AppLoadingIndicator from "./components/AppLoadingIndicator";
import loadingManager from "./utils/AppLoadingManager";

// Import smaller components directly
import Login from "./routes/Login.jsx";
import PublicLandingPage from "./routes/PublicLandingPage";
import RequestPasswordResetForm from "./routes/RequestPasswordResetForm";
import ResetPasswordFrom from "./routes/ResetPasswordFrom";
import Plans from "./routes/Plans";

// Lazy load everything else based on priority
// Priority 1: Calendar-related components (load right after initial render)
const Calendar = lazy(() => {
  const promise = import("./components/calendar/Calendar.jsx");
  promise.then(() => {
    loadingManager.completeStep("calendar-core");
  });
  return promise;
});

// Priority 2: Secondary routes (load only when needed)
const Settings = lazy(() => import("./routes/Settings.jsx"));
const Admin = lazy(() => import("./routes/Admin.jsx"));
const CreateTrialUser = lazy(() => import("./components/CreateTrialUser"));
const CreateUser = lazy(() => import("./components/CreateUser"));
const UserAccount = lazy(() =>
  import("./components/UserAccountInfo/UserAccount")
);
const Support = lazy(() => import("./routes/Support"));
const Payment = lazy(() => import("./routes/Payment"));
const PaymentAccount = lazy(() => import("./routes/PaymentAccount"));
const CompletedTasksViewer = lazy(() =>
  import("./components/calendar/CompletedTasksViewer/CompletedTasksViewer")
);
const ProjectTimeline = lazy(() => import("./components/TimeLine/TimeLine"));

// Component for fallback during chunk loading
const ChunkLoadingFallback = () => (
  <div className="flex items-center justify-center min-h-screen">
    <div className="p-4 text-center">
      <div className="animate-pulse mb-4">
        <div className="h-4 bg-gray-200 rounded w-12 mx-auto mb-2"></div>
        <div className="h-4 bg-gray-200 rounded w-20 mx-auto"></div>
      </div>
      <div className="text-sm text-gray-500">Loading...</div>
    </div>
  </div>
);

// Admin route component
const AdminRoute = ({ children }) => {
  const { user, loading, checkAuth } = useAuth();

  if (loading) {
    return <ChunkLoadingFallback />;
  }

  return children;
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <PublicLandingPage />,
  },
  {
    path: "/journal",
    element: (
      <ProtectedRoute>
        <Suspense fallback={<ChunkLoadingFallback />}>
          <Calendar />
        </Suspense>
      </ProtectedRoute>
    ),
  },
  {
    path: "/admin",
    element: (
      <AdminRoute>
        <Suspense fallback={<ChunkLoadingFallback />}>
          <Admin />
        </Suspense>
      </AdminRoute>
    ),
  },
  {
    path: "/settings",
    element: (
      <ProtectedRoute>
        <Suspense fallback={<ChunkLoadingFallback />}>
          <Settings />
        </Suspense>
      </ProtectedRoute>
    ),
  },
  {
    path: "/account",
    element: (
      <ProtectedRoute>
        <Suspense fallback={<ChunkLoadingFallback />}>
          <UserAccount />
        </Suspense>
      </ProtectedRoute>
    ),
  },
  {
    path: "/support",
    element: (
      <ProtectedRoute>
        <Suspense fallback={<ChunkLoadingFallback />}>
          <Support />
        </Suspense>
      </ProtectedRoute>
    ),
  },
  {
    path: "/payment",
    element: (
      <ProtectedRoute>
        <Suspense fallback={<ChunkLoadingFallback />}>
          <Payment />
        </Suspense>
      </ProtectedRoute>
    ),
  },
  {
    path: "/PaymentAccount",
    element: (
      <ProtectedRoute>
        <Suspense fallback={<ChunkLoadingFallback />}>
          <PaymentAccount />
        </Suspense>
      </ProtectedRoute>
    ),
  },
  {
    path: "/CompletedTasksViewer",
    element: (
      <ProtectedRoute>
        <Suspense fallback={<ChunkLoadingFallback />}>
          <CompletedTasksViewer />
        </Suspense>
      </ProtectedRoute>
    ),
  },
  {
    path: "/timeline",
    element: (
      <ProtectedRoute>
        <Suspense fallback={<ChunkLoadingFallback />}>
          <ProjectTimeline />
        </Suspense>
      </ProtectedRoute>
    ),
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/request-pw-reset",
    element: <RequestPasswordResetForm />,
  },
  {
    path: "/reset-password",
    element: <ResetPasswordFrom />,
  },
  {
    path: "/signup/trial",
    element: (
      <Suspense fallback={<ChunkLoadingFallback />}>
        <CreateTrialUser />
      </Suspense>
    ),
  },
  {
    path: "/signup",
    element: (
      <Suspense fallback={<ChunkLoadingFallback />}>
        <CreateUser />
      </Suspense>
    ),
  },
  {
    path: "/plans",
    element: <Plans />,
  },
  {
    path: "*",
    element: <Navigate to="/" replace />,
  },
]);

function ErrorFallback({ error }) {
  return (
    <div className="p-4">
      <h1 className="text-xl font-bold text-red-600">Something went wrong:</h1>
      <pre className="mt-2 p-2 bg-gray-100 rounded">{error.message}</pre>
    </div>
  );
}

// App.jsx
const App = () => {
  const [loading, setLoading] = useState(true);
  const [progress, setProgress] = useState(0);
  const [message, setMessage] = useState("Starting application...");

  useEffect(() => {
    // Subscribe to loading manager updates
    const unsubscribe = loadingManager.subscribe(({ progress, status }) => {
      setProgress(progress);
      setMessage(status);

      // Only hide loading screen when progress is 100%
      if (progress >= 100) {
        // Add a small delay to ensure smooth transition
        setTimeout(() => {
          setLoading(false);
        }, 500);
      }
    });

    // Load core components
    Promise.all([
      // Calendar core components
      import("./components/calendar/CalendarGrid").then(() => {
        loadingManager.completeStep("calendar-core");
      }),
      // Editor components
      import("./components/Tiptap/TiptapEditor").then(() => {
        loadingManager.completeStep("editor");
      }),
      // Features
      new Promise((resolve) => {
        setTimeout(() => {
          loadingManager.completeStep("calendar-features");
          resolve();
        }, 500);
      }),
      // Voice assistant
      new Promise((resolve) => {
        setTimeout(() => {
          loadingManager.completeStep("voice-assistant");
          resolve();
        }, 1000);
      }),
    ]);

    // Fallback timer in case something goes wrong
    const fallbackTimer = setTimeout(() => {
      if (loading) {
        console.warn("Loading taking too long, showing app anyway");
        loadingManager.setProgress(100, "Ready!");
      }
    }, 8000);

    return () => {
      unsubscribe();
      clearTimeout(fallbackTimer);
    };
  }, []);

  // Show AppLoadingIndicator when loading
  if (loading) {
    return (
      <AppLoadingIndicator
        progress={progress}
        status={message}
        isVisible={loading}
      />
    );
  }

  // Render app when loading is complete
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <ThemeProvider>
        <AuthProvider>
          <SettingsProvider>
            <VersionProvider>
              <RouterProvider router={router} />
            </VersionProvider>
          </SettingsProvider>
        </AuthProvider>
      </ThemeProvider>
    </ErrorBoundary>
  );
};

export default App;
