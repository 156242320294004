// Suppress specific warnings
const originalWarn = console.warn;
console.warn = (message, ...args) => {
  if (message.includes("React Router Future Flag Warning")) {
    return;
  }
  originalWarn(message, ...args);
};

import ReactDOM from "react-dom/client";
import { ToastContainer } from "react-toastify";
import App from "./App.jsx";
import loadingManager from "./utils/AppLoadingManager";

// Initialize loading manager before rendering
loadingManager.setProgress(10, "Starting application...");

// Import Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
// Import Bootstrap Bundle JS
import "bootstrap/dist/js/bootstrap.bundle.min";

// Import your custom CSS (adjust the path as needed)
import "./assets/css/themes.css";
import "./assets/css/CalendarGrid.css";
import "./assets/css/connectome-dark-blue-theme.css";
import "./assets/css/connectome-dark-theme.css";
import "./assets/css/connectome-styles.css";
import "./assets/css/Tiptap.css";

// Mark initial loading step as complete
loadingManager.completeStep("app-core");

ReactDOM.createRoot(document.getElementById("root")).render(
  <>
    <App />
    <ToastContainer autoClose={1000} />
  </>
);
