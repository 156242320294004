/**
 * Application Loading Manager
 * Helps coordinate and track loading of various app components
 */
class AppLoadingManager {
  constructor() {
    this.subscribers = new Set();
    this.loadingSteps = [
      {
        id: "app-core",
        weight: 15,
        loaded: false,
        status: "Initializing application...",
      },
      {
        id: "auth-check",
        weight: 5,
        loaded: false,
        status: "Checking authentication...",
      },
      {
        id: "calendar-core",
        weight: 20,
        loaded: false,
        status: "Loading calendar...",
      },
      {
        id: "editor",
        weight: 15,
        loaded: false,
        status: "Preparing editor...",
      },
      {
        id: "calendar-features",
        weight: 15,
        loaded: false,
        status: "Loading features...",
      },
      {
        id: "voice-assistant",
        weight: 20,
        loaded: false,
        status: "Initializing assistant...",
      },
      {
        id: "tensorflow",
        weight: 10,
        loaded: false,
        status: "Loading AI components...",
      },
    ];

    this.currentProgress = 0;
    this.currentStatus = "Starting up...";
  }

  /**
   * Subscribe to loading updates
   * @param {Function} callback Function to call with updates
   * @returns {Function} Unsubscribe function
   */
  subscribe(callback) {
    this.subscribers.add(callback);

    // Immediately send current state
    callback({
      progress: this.currentProgress,
      status: this.currentStatus,
    });

    // Return unsubscribe function
    return () => {
      this.subscribers.delete(callback);
    };
  }

  /**
   * Notify all subscribers of changes
   */
  notifySubscribers() {
    this.subscribers.forEach((callback) => {
      callback({
        progress: this.currentProgress,
        status: this.currentStatus,
      });
    });
  }

  /**
   * Mark a loading step as complete
   * @param {string} stepId ID of the step that completed
   */
  completeStep(stepId) {
    const step = this.loadingSteps.find((s) => s.id === stepId);

    if (step && !step.loaded) {
      step.loaded = true;

      // Recalculate progress
      this.calculateProgress();

      // Update status to the next incomplete step
      this.updateStatus();

      // Notify subscribers
      this.notifySubscribers();
    }
  }

  /**
   * Calculate current progress based on completed steps
   */
  calculateProgress() {
    const totalWeight = this.loadingSteps.reduce(
      (sum, step) => sum + step.weight,
      0
    );
    const loadedWeight = this.loadingSteps
      .filter((step) => step.loaded)
      .reduce((sum, step) => sum + step.weight, 0);

    this.currentProgress = Math.round((loadedWeight / totalWeight) * 100);
  }

  /**
   * Update status message based on next incomplete step
   */
  updateStatus() {
    const nextStep = this.loadingSteps.find((step) => !step.loaded);

    if (nextStep) {
      this.currentStatus = nextStep.status;
    } else {
      this.currentStatus = "Ready!";
    }
  }

  /**
   * Manually set the current progress and status
   * @param {number} progress Progress percentage (0-100)
   * @param {string} status Status message
   */
  setProgress(progress, status) {
    this.currentProgress = Math.min(Math.max(0, progress), 100);
    this.currentStatus = status || this.currentStatus;
    this.notifySubscribers();
  }

  /**
   * Reset loading state
   */
  reset() {
    this.loadingSteps.forEach((step) => {
      step.loaded = false;
    });

    this.currentProgress = 0;
    this.currentStatus = "Starting up...";
    this.notifySubscribers();
  }
}

// Create singleton instance
const loadingManager = new AppLoadingManager();

export default loadingManager;
